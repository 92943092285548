import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { useState } from "react"


function EditNFTDialog({ open, onClose, onSubmit }) {
    const [nameValue, setNameValue] = useState('')
    const [descValue, setDescValue] = useState('')
    const [fileSelected, setFileSelected] = useState()

    const handleSubmit = () => {
        if (typeof onSubmit === 'function') {
            onSubmit(nameValue, descValue, fileSelected)
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>
                Update NFT
            </DialogTitle>
            <DialogContent>
                <Stack spacing={1} sx={{ width: '100%' }}>
                    <TextField
                        label="Name"
                        placeholder="edit to update the name"
                        value={nameValue}
                        onChange={(e) => setNameValue(e.target.value)}
                    />
                    <TextField label="Description" variant="outlined"
                        placeholder="edit update the description"
                        value={descValue}
                        multiline
                        minRows={4}
                        maxRows={4}
                        onChange={(e) => setDescValue(e.target.value)} />
                    <Button
                        variant="contained"
                        component="label"
                    >
                        {fileSelected ? fileSelected.name : 'Upload to change media'}
                        <input
                            type="file"
                            onChange={(e) => {
                                setFileSelected(e.target.files[0])
                            }}
                            hidden
                        />
                    </Button>
                    <Button onClick={handleSubmit}>Update NFT</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default EditNFTDialog