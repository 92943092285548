import config from '../config'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: config.services.chainsList.baseurl
})

const api = createApi({
    reducerPath: 'chainListAPI',
    baseQuery,
    endpoints: () => ({}),
})

export default api;