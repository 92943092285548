import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    modal: {
        open: false,
        title: '',
        message: ''
    },
    loading: false
}

export const ModalSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showModal: (state, { payload }) => {
            console.log("show modal")
            state.modal = {
                ...payload,
                open: true
            }
        },
        clearModal: (state) => {
            console.log("clear modal")
            state.modal = initialState.modal
        },
        showLoading: (state) => {
            console.log("show loading")
            state.loading = true
        },
        loadingDone: (state) => {
            console.log("loading done")
            state.loading = false
        }
    }
})

// Action creators are generated for each case reducer function
export const { showModal, clearModal, showLoading, loadingDone } = ModalSlice.actions

export default ModalSlice.reducer