import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material"
import React from "react"
import { Cards } from "../../features/components"

const hardcodedCards = [
    {
        name: 'Timestamped Digital Ledger',
        description: 'Write arbitrary text to a chain. The time and content is preserved for ever, acting as proof of record. Typically, it would contain a hash of the asset you are recording, and signed with your private keys, so only you can read parts of it.',
        image: './static/images/ledger-ss.png',
        link: '/digital-ledger'
    },
    {
        name: 'Escrow',
        description: 'Create an escrow payment and release funds, when real world criteria has been met.',
        image: './static/images/escrow-ss.png',
        link: '/escrow'
    },
    {
        name: 'Web 3.0 Marketplace',
        description: 'Simple marketplace app that allows Web3 users to create an NFT, mint, but and sell it.',
        image: './static/images/marketplace-ss.png',
        link: '/marketplace'
    },
    {
        name: 'Royalty Platform',
        description: 'Markplace app with royalty payments, that allow both Web2 and Web3 users to create NFTs, mint, buy and sell them.',
        image: './static/images/royalty-ss.png',
        link: '/royalty-platform'
    },
    {
        name: 'SAID Reference Platform',
        description: 'A Reference Marketplace app with royalty payments that allows both Web2 and Web3 users to create NFTs, mint, buy and sell them.',
        image: './static/images/said-ss.png',
        link: '/said'
    }
]

function Dashboard() {

    // const handleAppClick = (goto) => {
    //     window.location.href = goto
    // }

    return (
        <React.Fragment>
            <Grid container spacing={1} justifyContent='center'>
                <Grid item>
                    <Card sx={{ maxWidth: 800 }}>
                        <CardContent>
                            <Box px={4} py={2}>
                                <Typography variant="h6" mb={2}>
                                    Firmview Studio creates seemless user experience for the token economy.
                                </Typography>
                                <Typography variant="body2" mb={1}>
                                    We build all types of blockchain applications including white labelled customised token storefronts,
                                    CRMs and revenue splitters to give you control over a seamless web3 user experience.
                                </Typography>
                                <Typography variant="body2">
                                    Below is a small collection of some simple demo apps. They are all running on the Sepolia testnet.
                                    Get some free test tokens here: <Link href='https://cloud.google.com/application/web3/faucet/ethereum/sepolia' target='_blank'>sepolia faucet</Link>
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center' padding={10}>
                {
                    hardcodedCards.map(({ name, description, image, link }, i) => (
                        <Grid item  key={`row_${i}`} sx={{ display: 'flex', alignItems: 'stretch' }}>
                            <Cards.ImageID src={image} id={i + 1} alt={name} component='a' href={link} target="blank" sx={{ textDecoration: 'none', minWidth: 300 }}>
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Typography gutterBottom paddingTop='1em' variant="h6" component="div" color='primary'>
                                        {name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {description}
                                    </Typography>
                                </CardContent>
                            </Cards.ImageID>
                        </Grid>
                    ))
                }
            </Grid>

        </React.Fragment>
    )
}

export default Dashboard