import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import {
    persistStore, persistReducer, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appReducer from '../../App.slice'
import chainListAPI from '../services/chainListAPI'

const reducers = combineReducers({
    app: appReducer,
    [chainListAPI.reducerPath]: chainListAPI.reducer,
})


const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        chainListAPI.reducerPath,
    ],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(chainListAPI.middleware)

        return middlewares
    },
})
const persistor = persistStore(store)

setupListeners(store.dispatch)

export { store, persistor }