import React, { useState } from 'react';
import { AppBar, Drawer, Modal, Box, Toolbar, Typography, IconButton, List, ListItem, ListItemButton, ListItemText, Backdrop, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearModal } from './App.slice'
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from './app/pages/dashboard.js';

const pages = {
  "Studio": Dashboard,
}

function App() {
  const dispatch = useDispatch()
  const { open: modal, title: modalTitle, message: modalMessage } = useSelector(state => state.app.modal)
  const { loading } = useSelector(state => state.app)
  const [draw, setDraw] = useState(false)
  const [page, setPage] = useState(Object.keys(pages)[0])

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setDraw(!draw)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            FirmviewGroup: Startup Studio
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor='left'
        open={draw}
        onClose={() => setDraw(false)}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', minWidth: 200 }}>
          <List>
            {
              Object.keys(pages).map(page => (
                <ListItem disablePadding key={page}>
                  <ListItemButton onClick={() => {
                    setPage(page)
                    setDraw(false)
                  }}>
                    <ListItemText primary={page} />
                  </ListItemButton>
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {pages[page]()}
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
      <Modal
        open={modal}
        onClose={() => dispatch(clearModal())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalMessage}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default App;