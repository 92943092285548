import Add from "./buttons/add";
import Delete from "./buttons/delete";
import Ballot from "./buttons/ballot";

import EtherSelection from "./inputs/ethSelection";

import EditNFT from "./dialogs/editNFT";

import ImageID from "./cards/imageId";

export const Buttons = {
    Add,
    Delete,
    Ballot,
}

export const FormInputs = {
    EtherSelection,
}

export const Dialogs = {
    EditNFT
}

export const Cards = {
    ImageID
}