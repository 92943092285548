import React from 'react';
import { Card, CardMedia, Box, Typography, Paper, CardActionArea, createStyles } from '@mui/material';

// Head of a MUI Card with embedded media
// Pass children in body to add CardContent and CardActions.
function ImageID({ alt, src, id, children, sx, ...rest }) {
    return (
        <React.Fragment>
            <Card elevation={5} sx={{ backgroundColor: '#eee', maxWidth: 300, position: 'relative', ...sx }} {...rest}>
                <CardActionArea sx={{ height: '100%', display: 'flex', alignItems: 'start' }}>
                    <Box>
                        <Box sx={{ backgroundColor: '#eee', width: "100%", alignSelf: 'start' }}>
                            <CardMedia sx={{ width: 300, height: 240, background: 'white' }}
                                component="img"
                                src={src}
                                alt={alt}
                            />
                            <Paper sx={styles.NFTIDCircle} elevation={3}>
                                <Typography>
                                    #{id}
                                </Typography>
                            </Paper>
                        </Box>
                        {children}
                    </Box>
                </CardActionArea>
            </Card>
        </React.Fragment>
    )
}

export const styles = createStyles({
    NFTIDCircle: {
        position: 'relative',
        marginTop: '-10%',
        marginBottom: '-10%',
        left: '39%',
        borderRadius: '5em',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: 1,
        width: 50,
        height: 50
    }
})


export default ImageID