const config = {
    web3: {
        defaultNetworkRPC: "wss://ropsten.infura.io/ws/v3/fbfe679e20f643e38f07fb524157847b"
    },
    services: {
        /*
        * Golang api
        */
        golang: {
            baseurl: process.env.REACT_APP_GOLANG_API_BASEURL || "https://crypto-api.firmview.co.uk"
        },
        /*
        * Chainlist has a URL that provides a list of all networks
        * We can use this with RTK and cache the results.
        * 
        * Then we can use that list to lookup human friendly names for the networkIds
        * Along with other relevent information about the network.
        */
        chainsList: {
            baseurl: "https://chainid.network"
        },
        /*
        * The ipfs is the standard way of providing NFTs with tokenURIs
        * We have a remote test server setup with basic authorization.
        * This server gives us access to the ipfs kudo api without a 3rd party provider.
        * We can use it to upload our NFTs metadata file and media.
        * Then we can pin the files on remote test server so that they remain persistent forever (as long as machine is alive).
        * ipfs will also distribute our files on the p2p (peer to peer) network.
        * However if not pinned by us or another peer
        * the files will eventually be garbage collected.
        */
        ipfs: {
            apiurl: process.env.REACT_APP_IPFS_APIURL || "https://ipfs.justdaile.co.uk/api/v0",  // API for uploading and pinning files.
            auth: process.env.REACT_APP_IPFS_AUTHENTICATION_HEADER || "Basic ZGFpbGU6SnVzdGFkZHdhdGVyMTk5MQ==", // API authentication header (Authozation: this)
            publicServer: process.env.REACT_APP_IPFS_PUBLIC_SERVER || "https://ipfs.io/ipfs"           // Public server used to display files on client.
        }
    }
}

export default config